<template>
  <div class="aboutUs">
    <div class="banner">
      <div class="container bannerContainer">
        <div>
          <p><strong>国家区块链创新应用试点项目</strong></p>
          <span>让知识链接未来</span>
        </div>
        <img class="bannerBg" src="../assets/images/aboutUsBannerImg.png" alt="">
      </div>
    </div>
    
    <div class="contentMenuBox">
      <div class="contentMenu" :class='{ fixedNavbar: isfixTab }'>
        <div class="container">
          <ul>
            <li><a :class="{'active': menuIndex == 1}" @click="menuIndex = 1,goAnchor('#content1')">平台建设单位</a></li>
            <li><a :class="{'active': menuIndex == 2}" @click="menuIndex = 2,goAnchor('#content2')">平台建设背景</a></li>
            <li><a :class="{'active': menuIndex == 3}" @click="menuIndex = 3,goAnchor('#content3')">平台介绍</a></li>
            <li><a :class="{'active': menuIndex == 4}" @click="menuIndex = 4,goAnchor('#content4')">合作伙伴</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div id="content1" class="content1">
      <div class="container">
        <div class="title">中文出版物知识产权评价中心</div>
        <div class="content1Box">
          <a-row>
            <a-col class="content1L" :md="24" :lg="9" :xl="9">
              <img src="../assets/images/aboutUsImg01.png" alt="">
            </a-col>
            <a-col class="content1R" :md="24" :lg="15" :xl="15">
              <p>唯一从事中文出版物知识产权评价的事业法人机构</p>
              <span> 中文出版物知识产权评价中心（Chinese Publications Intellectual Property Evaluation Center）（中文简称“中文著作权评价中心”，英文简称“CPIP”）经湖北省编制办批准成立，湖北省事业单位登记管理局登记颁证 [事证第124200005914897162号] ，是国内唯一从事中文出版物知识产权评价的事业法人机构。中心的宗旨和业务范围是中文出版物知识产权评价和标准研究制定；知识产权及版权公益服务；促进知识产权建设和产业发展等。</span>
              <p>国家级区块链创新应用试点项目</p>
              <span>中文出版物知识产权评价中心承担中央网信办、中宣部、国家版权局国家级区块链创新应用试点项目“中国知链”。2023年1月，根据《关于印发国家区块链创新应用试点名单的通知》(中网办秘字[2022]72 号)要求，经过对技术平台建设、应用服务建设、产业生态建设、社会经济效益等方面的综合评审，“中国知链”已审核通过。</span>
              <p>全国学术论文(著作)版权登记认证服务平台</p>
              <span>中文出版物知识产权评价中心与“万方科技”合作建设运营“全国学术论文(著作)版权登记认证服务平台”，积极开展学术论文版权登记和认证体系建设，推动科研诚信有效运行 。在知识产权强国大背景下，“全国学术论文(著作)版权登记认证服务平台”必将产生深远影响。 </span>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>

    <div id="content2" class="content2">
      <div class="container">
        <div class="title">国家区块链创新应用试点</div>
        <a-row class="content2T">
            <a-col class="content2Box" :md="24" :lg="8" :xl="8">
              <div>
                <img src="../assets/images/aboutUsIcon01.png" alt="">
                <p>根据中央网信办《关于组织申报区块链创新应用试点的通知》（中网办秘字〔2021〕1482号），经过中央网信办、中央宣传部、国务院办公厅、教育部、工业和信息化部等17个中央委办部门和单位严格评审，中国版权保护中心、中国搜索信息科技股份有限公司、武汉大学等十二家单位获批“国家区块链创新应用试点”。</p>
              </div>
            </a-col>
            <a-col class="content2Box" :md="24" :lg="8" :xl="8">
              <div>
                <img src="../assets/images/aboutUsIcon01.png" alt="">
                <p>武汉大学“中国知链-基于区块链技术的学术论文知识产权保护及认证可信体系建设”（以下简称“中国知链”，国家互联网信息办公室区块链信息服务备案编号42011122891907500013号）是国家区块链创新应用试点“区块链+版权+学术论文”唯一项目，由中文出版物知识产权评价中心开发。</p>
              </div>
            </a-col>
            <a-col class="content2Box" :md="24" :lg="8" :xl="8">
              <div>
                <img src="../assets/images/aboutUsIcon01.png" alt="">
                <p>“中国知链” 将实现国内首个学术论文领域“区块链学术论文版权保护案例”从【0】到【1】的突破，首期在全国设立30个学术论文区块链节点，中国知链和万方科技以公信力节点接入参与国家“区块链+版权”的建设。 </p>
              </div>
            </a-col>
        </a-row>
        <a-row class="content2B">
            <a-col class="content2Box2" :md="12" :lg="6" :xl="6"><img src="../assets/images/aboutUsImg02.png" alt=""></a-col>
            <a-col class="content2Box2" :md="12" :lg="6" :xl="6"><img src="../assets/images/aboutUsImg03.png" alt=""></a-col>
            <a-col class="content2Box2" :md="12" :lg="6" :xl="6"><img src="../assets/images/aboutUsImg04.png" alt=""></a-col>
            <a-col class="content2Box2" :md="12" :lg="6" :xl="6"><img src="../assets/images/aboutUsImg05.png" alt=""></a-col>
        </a-row>
      </div>
    </div>

    <div id="content3" class="content3">
        <div class="title">全国学术论文(著作)版权登记认证服务平台</div>
        <div class="content3T">
          <div class="container">
            <a-row>
                <a-col class="content3Text" :md="24" :lg="16" :xl="16">
                  <p>全国学术论文(著作)版权登记服务平台</p>
                  <span>“全国学术论文(著作)版权登记服务平台”以区块链技术为核心支撑，为论文颁发作品登记证书，利用电子签名和数字证书建立起可信赖、可查验的安全认证体系，以标识、验证、特征提取、监测取证等系统化的集成应用平台实现内容确权、版权授权结算在线化、版权维权举证标准化。提供国家法律认可的版权确权、权属确认、授权结算、维权保护等服务。登记成功的论文权属确认、权属转移、信用承诺、同行评议等数据区块链存证，可信，可回溯，数据进入“全国作品登记信息数据库”和国家司法系统。</span>
                </a-col>
                <a-col class="content3Text" :md="0" :lg="1" :xl="1"></a-col>
                <a-col class="content3Img" :md="24" :lg="7" :xl="7">
                  <div><img src="../assets/images/aboutUsImg06.png" alt=""><p>湖北省版权局作品登记证书样本</p></div>
                </a-col>
            </a-row>
          </div>
        </div>
        <div class="content3B">
          <div class="container">
            <a-row>
                <a-col class="content3Img" :md="24" :lg="7" :xl="7">
                  <div><img src="../assets/images/aboutUsImg07.png" alt=""><p>国家版权局作品登记证书（DCI证书）样本</p></div>
                </a-col>
                <a-col class="content3Text" :md="0" :lg="1" :xl="1"></a-col>
                <a-col class="content3Text" :md="24" :lg="16" :xl="16">
                  <p>DCI体系3.0版权登记认证</p>
                  <span>“全国学术论文(著作)版权登记认证服务平台”加入数字作品版权链（DCI体系3.0）数字作品版权登记业务试点应用，参与业务公测。 平台通过国家版权公共服务的体系化模式创新，面向学术期刊互联网平台用户提供“即时申领DCI，按需办理数字作品版权登记”的版权权属认证服务。以DCI体系3.0版权登记认证推动科研诚信有效运行，解决版权和学术科研诚信的关键问题，实现科研诚信协同治理。中文出版物知识产权评价中心评价重点在价值导向、评价分类、评价指标等方面，将科研诚信状况作为评价的重要指标。中文出版物知识产权评价中心通过DCI体系3.0，为学术期刊单位开展国家事业公益性评价及其它第三方专业多维度的服务，支撑数字内容价值进一步释放，促进数字内容授权与价值转化。</span>
                </a-col>
            </a-row>
          </div>
        </div>
    </div>

    <div id="content4" class="content4">
      <div class="title">合作伙伴</div>
      <div class="container">
        <a-row>
            <a-col class="content4Li" :md="12" :lg="6" :xl="6"><div class="content4Box"><img src="../assets/images/aboutUsImg08.png" alt=""></div></a-col>
            <a-col class="content4Li" :md="12" :lg="6" :xl="6"><div class="content4Box"><img src="../assets/images/aboutUsImg09.png" alt=""></div></a-col>
            <a-col class="content4Li" :md="12" :lg="6" :xl="6"><div class="content4Box"><img src="../assets/images/aboutUsImg10.png" alt=""></div></a-col>
            <a-col class="content4Li" :md="12" :lg="6" :xl="6"><div class="content4Box"><img src="../assets/images/aboutUsImg11.png" alt=""></div></a-col>
        </a-row>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      menuIndex: 1,
      isfixTab: false,
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleTabFix, true) // 监听页面滚动
  },
  methods: {
      //模拟锚点跳转
    goAnchor(selector) {//参数selector是id选择器
        document.querySelector(selector).scrollIntoView({
            behavior: "smooth"
        });
    },

    // 先分别获得id为testNavBar的元素距离顶部的距离和页面滚动的距离
	    // 比较他们的大小来确定是否添加fixedNavbar样式
    handleTabFix() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      scrollTop >=400 ? this.isfixTab = true : this.isfixTab = false
      // console.log(scrollTop);
      // console.log(document.documentElement.clientHeight);

      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
   		var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;

      if(scrollTop < 1170){
        this.menuIndex = 1
      }else if(scrollTop >= 1170 && scrollTop < 2000){
        this.menuIndex = 2
      }else if(scrollTop >= 2000){
        this.menuIndex = 3
      }
      if(scrollTop+windowHeight==scrollHeight){ // 页面到底了
        // console.log('到底了');
        this.menuIndex = 4
      }
    }

  },
}
</script>

<style lang="scss" scoped>
.banner{
  width: 100%;
  height: 400px;
  position: relative;
  background: url(../assets/images/aboutUsBanner.png)no-repeat;
  background-size: cover;
  .bannerContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    div{
      p{
        font-size: 48px;
        font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
        font-weight: 400;
        color: #000000;
        line-height: 81px;
        strong{
          color: #FFFFFF;
        }
      }
      span{
        display: inline-block;
        font-size: 20px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #163B61;
        line-height: 40px;
        color: #FFFFFF;
        border: 2px solid;
        border-image: linear-gradient(90deg, #ADEBFD, #94ABFF) 10 10;
        border-radius: 0px 11px 0px 11px;
        letter-spacing: 10px;
        padding: 0 10px 0 20px;
        margin-top: 20px;
      }
    }
    img{
      height: 400px;
    }
  }
}
.contentMenuBox{
  height: 60px;
}
.contentMenu{
  background: #FFFFFF;
  ul{
    border-bottom: 1px solid #ECEEEF;
    height: 60px;
    display: flex;
    align-items: center;
    li{
      a{
        display: block;
        height: 60px;
        line-height: 60px;
        margin-right: 40px;
        font-size: 18px;
        font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
        color: #000000;
      }
      a.active{
        color: #1353B4;
      }
    }
  }
}
.fixedNavbar {
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 60px;
  left: 0;
  border-top: 1px solid #ECEEEF;
  border-bottom: 1px solid #ECEEEF;
  box-shadow: rgba(0, 19, 48, 0.02) 0px 1px 1px 0px;
  ul{
    border-bottom: none;
  }
}
.title{
  font-size: 36px;
  font-family: 'SourceHanSansCN-Medium, SourceHanSansCN';
  font-weight: 500;
  color: #0A3372;
  line-height: 54px;
  text-align: center;
}
.content1{
  background: #FFFFFF;
  padding: 60px 0;
  .content1Box{
    box-shadow: 0px 0px 14px 0px rgba(219,227,255,0.67);
    border-radius: 8px;
    background: #FFFFFF;
    margin-top: 30px;
    padding: 30px 50px;
    .content1L{
      padding: 10px;
      img{
        width: 100%;
        margin: 80px 0;
      }
    }
    .content1R{
      padding: 10px;
      p{
        font-size: 18px;
        font-family: 'SourceHanSansCN-Medium, SourceHanSansCN';
        font-weight: 500;
        color: #2A2F3A;
        line-height: 27px;
        position: relative;
        padding-left: 18px;
        margin: 20px 0 10px 0;
      }
      p:first-child{
        margin-top: 0;
      }
      p::before{
        content: '';
        width: 10px;
        height: 10px;
        background: #316EF4;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 8px;
      }
      span{
        display: block;
        font-size: 14px;
        font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
        font-weight: 400;
        color: #596178;
        line-height: 28px;
        padding-left: 18px;
      }
    }
  }
}
.content2{
  padding: 40px 0;
  background: url(../assets/images/aboutUsContent2Bg.png)no-repeat;
  background-size: cover;
  .title{
    color: #FFFFFF;
  }
  .content2Box{
    margin:  30px 0;
    div{
      background: rgba(255,255,255,0.8);
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      height: 350px;
      padding: 30px 20px;
      margin: 0 20px;
      img{
        width: 60px;
        height: 60px;
        display: block;
        margin: 0 auto 30px auto;
      }
      p{
        font-size: 14px;
        font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
        font-weight: 400;
        color: #464B58;
        line-height: 28px;
      }
    }
  }
  .content2B{
    padding: 0 30px;
    .content2Box2{
      padding: 0 30px;
      img{
        width: 100%;
      }
    }
  }
}
.content3{
  padding-top: 60px;
  background: #FFFFFF;
  .content3T{
    padding: 60px 0 30px 0;
  }
  .content3B{
    padding: 40px 0;
    background: #F9FBFF;
  }
  .content3Text{
    padding: 40px 0;
    p{
      font-size: 18px;
      font-family: 'SourceHanSansCN-Medium, SourceHanSansCN';
      font-weight: 500;
      color: #2A2F3A;
      line-height: 27px;
      margin-bottom: 10px;
    }
    span{
      font-size: 14px;
      font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
      font-weight: 400;
      color: #596178;
      line-height: 28px;
    }
  }
  .content3Img{
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px 0px rgba(49,110,244,0.14);
    border-radius: 8px;
    img{
      width: 100%;
    }
    p{
      font-size: 14px;
      font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';
      font-weight: 400;
      color: #000000;
      line-height: 24px;
      text-align: center;
    }
  }
}
.content4{
  padding: 40px 0 80px 0;
  background: #FFFFFF;
  .content4Li{
    .content4Box{
      margin-top: 30px;
      border: 1px solid #E4E8EF;
      border-right: none;
      img{
        display: block;
        width: 80%;
        margin: 25px auto;
      }
    }
  }
  .content4Li:nth-child(4n){
    .content4Box{
      border-right: 1px solid #E4E8EF;
    }
  }
}
// 在元素滚动到达时添加一个额外的距离，以避免滚动到头部fixed布局之下-------------------------------
.content1,.content2,.content3,.content4{
  scroll-margin-top: 120px;
}
// 适配移动端
@media screen and (max-width: 991px){
  .banner{
    height: 250px;
    div{
      p{
        font-size: 28px!important;
      }
      span{
        margin-top: 0!important;
      }
    }
  }
  .content1{
    padding: 40px 0;
  }
  .bannerContainer{
    position: relative;
    padding: 50px 20px;
    img{
      display: none;
      position: absolute;
      left: 10%;
      top: 10%;
      z-index: 0;
      width: 80%;
      height: 80%!important;
    }
    div{z-index: 1;}
  }
  .contentMenu{
    ul{
      li{
        a{
          display: block;
          margin-right: 15px;
          text-align: center;
          font-size: 16px;
        }
      }
    }
  }
  // .fixedNavbar{
  //   position: fixed;
  //   top: 80px;
  //   left: 0;
  // }
  .title{
    font-size: 26px;
    padding: 0 20px;
  }
  .content1Box{
    padding: 30px 10px!important;
    .content1L img{
      margin: 10px 0!important;
    }
  }
  .content2 .content2B .content2Box2{
    padding: 10px;
  }
  .content3 .content3T{
    padding: 0  0 30px 0;
  }
  .content3 .content3Text{
    padding: 0;
  }
  .content3B{
    position: relative;
    padding-bottom: 360px!important;
  }
  .content3B .content3Img{
    position: absolute;
    bottom: -320px;
  }

  .ant-col-md-12{
    width: 50%;
    float: left;
    height: 210px;
  }
  .ant-col-md-0{
    display: none;
  }
  .content4Li:nth-child(2n){
    .content4Box{
      border-right: 1px solid #E4E8EF;
    }
  }
  .content4 .ant-col-md-12{
    height: 120px;
  }
}
</style>
